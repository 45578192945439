<template>
  <div class="content-wrapper">
    <div class="content">
      <h4>
        skuCode：
        <span class="textGrey">{{ $route.query.id }}</span>
      </h4>
      <a-table
        :columns="tableColumns"
        :data-source="tableData"
        rowKey="createdAt"
        :pagination="false"
        bordered
      ></a-table>

      <div class="table-footer">
        <pagination
          :pageNo="pageNo"
          :length="pageSize"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import http from '@service/axios'
import api from '@service/api'
import pagination from '@component/pagination'
export default {
  name: 'productOperationLog',
  mounted() {
    this.skuOperateLogPageList()
  },
  components: { pagination },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableColumns: [
        {
          title: this.$t('创建者'),
          dataIndex: 'createdUser',
          key: 'createdUser',
        },
        {
          title: this.$t('操作时间'),
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: this.$t('操作'),
          dataIndex: 'operate',
          key: 'operate',
        },
      ],
      tableData: [],
    }
  },
  computed: {},
  methods: {
    skuOperateLogPageList() {
      http({
        instance: this,
        type: 'post',
        url: api.skuOperateLogPageList,
        hasLoading: true,
        data: {
          skuCode: this.$route.query.id || '',
          pageSize: this.pageSize,
          pageNo: this.pageNo,
        },
        success: (res) => {
          this.tableData = res.result.operateLogList
          this.total = res.result.total
        },
      })
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.skuOperateLogPageList()
    },
  },
}
</script>

<style lang="scss" scoped>
.product-operation-log {
  padding: 0 20px 20px;
}
.textGrey {
  color: #938c8c !important;
}
</style>
