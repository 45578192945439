<template>
  <div class="content-wrapper platform-goods-detail">
    <div class="content" style="min-height: 0">
      <a-tabs default-active-key="1" @tabClick="linkTo">
        <a-tab-pane
          v-for="categoryItem in categoryAttributeList"
          :key="categoryItem.label"
          :tab="categoryItem.title"
          force-render
        ></a-tab-pane>
      </a-tabs>
    </div>
    <!-- Main content -->
    <section class="content fixContent" :style="{ height: mainHeight }">
      <a-form-model :model="formData" v-bind="formItemLayout">
        <!-- 这里通过categoryAttributeList来自动生成表单 -->
        <div
          class="category-attribute-item"
          v-for="categoryItem in categoryAttributeList"
          :key="categoryItem.title"
        >
          <!-- <h5 class="title" :id="categoryItem.title">
            {{ categoryItem.title }}
          </h5> -->
          <div class="infoTitle" :id="categoryItem.label">
            <i class="leftLine"></i>
            {{ categoryItem.title }}
          </div>

          <div v-for="(item, index) in categoryItem.list" :key="index">
            <a-form-model-item
              :key="item.name"
              class="attr-item"
              v-show="index < 2 || categoryItem.showMore"
              :required="!!item.is_mandatory"
              :label="item.label"
            >
              <a-col :span="16">
                <selectGoodsBrand
                  v-if="item.label === 'Brand' || item.label === 'brand'"
                  :primaryCategoryId="primaryCategoryId"
                  :shopCode="shopCode"
                  :platform="platform"
                  :disabled="!is_edit"
                  :placeholder="'--' + $t(`无品牌`) + '--'"
                  @selected="selectGoodsBrand"
                  :select="formData.brand"
                ></selectGoodsBrand>
                <publishModalInput
                  v-else
                  :disabled="!is_edit || (item.modify !== undefined && !+item.modify)"
                  :name="item.name"
                  :options="item.options"
                  :type="item.input_type"
                  :shopCode="shopCode"
                  :value.sync="formData[item.name]"
                ></publishModalInput>
              </a-col>
            </a-form-model-item>
          </div>
          <a-form-model-item :label="$t(`类目`)" required v-if="categoryItem.title === '基本信息'">
            <a-col :span="16">
              <categoryTree
                :defaultName="formData.categoryChain"
                :disabled="!!formData.itemId"
                @changeCategory="changeCategory"
              ></categoryTree>
            </a-col>
          </a-form-model-item>
          <a-row v-if="categoryItem.list.length > 2">
            <a-col class="text-info" :span="3" @click="showMoreOrLess(categoryItem)">
              Show
              <span v-if="categoryItem.showMore">
                Less
                <i class="fa fa-caret-up"></i>
              </span>
              <span v-else>
                More
                <i class="fa fa-caret-down"></i>
              </span>
            </a-col>
          </a-row>

          <a-form-model-item
            :label="$t(`SKU规格/价格/库存`)"
            v-if="categoryItem.title === 'sku信息'"
          >
            <sku-setting
              ref="skuChild"
              page="platformGoodsDetail"
              :is_edit="is_edit"
              :styleTypeList="styles"
              :defaultTableData="sku_list"
              :propsColumns="tableColumns"
              :skuAttributes="skuAttributes"
            ></sku-setting>
          </a-form-model-item>
        </div>
      </a-form-model>
    </section>
    <div class="content" style="min-height: 0">
      <a-row type="flex" justify="center">
        <a-col :span="10">
          <a-row style="margin-top: 4px">
            <!-- <a-col :span="8">当前填写完成度{{ speed.number }}%</a-col> -->
            <!-- <a-col :span="18" :offset="2"
              ><a-progress v-if="is_edit" :percent="speed.number"
            /></a-col> -->
          </a-row>
        </a-col>
        <a-col :span="14">
          <a-row type="flex" justify="start" :gutter="16">
            <a-row type="flex" justify="center"></a-row>
            <a-col v-if="!is_edit && !detail_type">
              <a-button type="primary" @click="is_edit = true">
                {{ $t('编辑') }}
              </a-button>
            </a-col>

            <a-col>
              <a-button type="primary" @click="visible = true" v-if="detail_type === 'commit'">
                {{ $t('提交审核') }}
              </a-button>
              <template v-else-if="detail_type === 'audit'">
                <a-button type="primary" @click="auditLazadaGoods(true)">
                  {{ $t('审核成功') }}
                </a-button>
                <a-button type="danger" @click="auditLazadaGoods(false)">
                  {{ $t('审核失败') }}
                </a-button>
              </template>
              <a-button type="primary" @click="confirmCreate" v-else-if="is_edit">
                {{ $t('保存') }}
              </a-button>
            </a-col>
            <a-col>
              <a-button @click="goBack">
                {{ $t('返回') }}
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <!-- 提交审核 -->
    <commitLazadaGoods
      :visible.sync="visible"
      :itemId="formData.itemId"
      @handleOk="handleOk"
    ></commitLazadaGoods>
  </div>
</template>
<script>
import http from '@service/axios'
import api from '@service/api'
import categoryTree from '@component/categoryTree'
import selectGoodsBrand from '@component/selectGoodsBrand'
import commitLazadaGoods from '@component/commitLazadaGoods'
import publishModalInput from '@component/publishModalInput'
import skuSetting from '@component/skuSetting'
export default {
  name: 'platformGoodsDetail',
  activated() {
    this.clear()
    let itemId = this.$route.query.id
    this.shopCode = this.$route.query.shopCode
    this.platform = this.$route.query.platform
    this.detail_type = localStorage.getItem('detail_type') || ''
    this.is_edit = localStorage.getItem('is_edit') || ''
    if (itemId) {
      this.formData.itemId = itemId
      this.queryPlatformGoodsDetail()
    }
  },
  components: {
    skuSetting,
    categoryTree,
    selectGoodsBrand,
    commitLazadaGoods,
    publishModalInput,
  },
  provide() {
    return {
      page: 'platformGoodsDetail',
    }
  },
  data() {
    return {
      barName: 'editProduct',
      formItemLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
      },
      visible: false,
      shopCode: '',
      activityPrice: '', //特殊价格
      formData: {
        itemId: '',
        name: '',
        categoryChain: '',
        brand: '',
        shortDescription: null,
        description: null,
        lazadaSkuList: [],
        packageWeight: '',
        packageHeight: '',
        packageWidth: '',
        packageLength: '',
      },
      is_edit: false,
      is_scroll: false,
      detail_type: '',
      categoryAttributeList: [],
      skuAttributes: [],
      styles: [],
      sku_list: [],
      categoryAttributeObj: {},
      attributeTypeList: [
        // "Package Length (cm)",
        // "Package Width (cm)",
        // "Package Height (cm)",
        // "Package Weight (kg)",
        // "Bundle Size",
      ], //过滤不需要的规格表格的字段
      primaryCategoryId: undefined,
      platform: undefined,
    }
  },
  computed: {
    // sku的表头
    tableColumns() {
      let arr_mapping = [
        {
          dataIndex: 'styleTypeName',
          key: 'styleTypeName',
          slots: { title: 'customTitle' },
          width: 150,
        },
      ]
      this.skuAttributes.forEach((item) => {
        arr_mapping.push({
          title: item.label,
          dataIndex: item.label,
          key: item.name,
          scopedSlots: { customRender: item.name },
        })
      })
      if (arr_mapping.length) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.is_scroll = true
      }
      // arr_mapping.push({
      //   title: "Status",
      //   dataIndex: "Status",
      //   key: "Status",
      //   scopedSlots: { customRender: "Status" },
      // });
      return [...arr_mapping]
    },
    mainHeight() {
      return (window.innerHeight - 370) * this.$store.state.rate + 'px'
    },
  },
  methods: {
    linkTo(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    selectGoodsBrand(data) {
      if (data) {
        this.formData.brand = data
      } else {
        this.formData.brand = ''
      }
    },
    goBack() {
      this.$router.push({ name: 'platformGoods' })
    },
    showMoreOrLess(item) {
      item.showMore = !item.showMore
    },
    queryPlatformGoodsDetail() {
      http({
        instance: this,
        type: 'post',
        url: api.queryPlatformDetail,
        hasLoading: true,
        data: {
          itemId: this.formData.itemId,
          shopCode: this.shopCode,
          platform: this.platform,
        },
        success: (res) => {
          let platformGoods = res.result.platformGoods,
            categoryAttributeList = res.result.categoryAttributeList,
            styles = res.result.styles
          this.platform = res.result.platform
          this.primaryCategoryId = platformGoods.primaryCategory || platformGoods.primary_category
          platformGoods.skus = platformGoods.skus ? platformGoods.skus : platformGoods.sku_res_list
          if (platformGoods.skus.length > 0) {
            platformGoods.skus.forEach((item) => {
              let skuStyleList = []
              item.skuImageList = item?.Images?.map((elem, index) => ({
                idx: index,
                url: elem,
              }))
              styles.forEach((elem) => {
                if (item[elem.name]) {
                  skuStyleList.push({
                    styleType: elem.name,
                    styleValue: item[elem.name],
                    textAvailable: this.judegInputType(elem.input_type),
                  })
                }
              })
              item.skuStyleList = [...skuStyleList]
            })
          } else {
            styles = []
          }

          styles.forEach((item) => {
            this.$set(item, 'styleType', item.name)
            this.$set(item, 'styleTypeName', item.label)
            this.$set(item, 'textAvailable', this.judegInputType(item.input_type))
            let styleList = categoryAttributeList.find((elem) => elem.label === item.label).options
            item.styleList =
              styleList?.map((elem) => ({
                styleId: elem.name,
                styleValue: elem.name,
              })) || []
            item.value_list =
              (item.values &&
                item.values.map((elem, index) => {
                  let arr =
                    (elem.images &&
                      [...elem.images].map((img_item, img_index) => ({
                        uid: '-' + (img_index + 1),
                        name: `image${img_index}.png`,
                        status: 'done',
                        url: img_item,
                      }))) ||
                    []
                  return {
                    styleTypeId: elem.styleValue + index,
                    styleTypeName: elem.styleValue,
                    textAvailable: this.judegInputType(item.input_type),
                    uploadImageUrlList: arr,
                  }
                })) ||
              []
          })
          this.formData = platformGoods.attributes
          this.formData.itemId = platformGoods.item_id
          this.formData.categoryChain = platformGoods.categoryChain
          let mapList = new Map(),
            skuAttributes = []
          categoryAttributeList.forEach((item) => {
            if (item.name == 'quantity') {
              item.label = 'Quantity'
            }
            if (!item.is_sale_prop) {
              if (item.attribute_type === 'normal') {
                if (mapList.has(item.panel)) {
                  let _list = mapList.get(item.panel)
                  _list.push(item)
                  mapList.set(item.panel, _list)
                } else {
                  mapList.set(item.panel, [item])
                }
              } else if (
                item.attribute_type === 'sku' &&
                this.attributeTypeList?.indexOf(item.label) < 0
              ) {
                skuAttributes.push(item)
              }
            }
            if (!item.options) {
              item.options = []
            }
          })
          let categoryAttributeShowList = []
          mapList.forEach((value, key) => {
            categoryAttributeShowList.push({
              title: key,
              showMore: false,
              list: value,
            })
          })
          categoryAttributeShowList.splice(2, 0, {
            title: 'sku信息',
            showMore: false,
            list: [],
          })
          this.categoryAttributeList = categoryAttributeShowList
          this.categoryAttributeList.forEach((item) => {
            item.label = item.title.indexOf('&') > -1 ? item.title.split('&')[0].trim() : item.title
          })
          // 这里制造Sku表头数据
          if (res.result.platform == 'LAZADA') {
            skuAttributes.push({
              advanced: '',
              attribute_type: 'sku',
              id: 'status',
              input_type: 'switch',
              is_mandatory: 1,
              is_sale_prop: 0,
              label: 'Status',
              modify: 1,
              name: 'Status',
              options: [],
              panel: 'sku信息',
            })
          }

          skuAttributes.forEach((item, index) => {
            if (item.label === 'SellerSKU') {
              skuAttributes.splice(1, 0, skuAttributes.splice(index, 1)[0])
            }
            if (item.label === 'Package Weight (kg)') {
              skuAttributes.splice(skuAttributes.length - 5, 0, skuAttributes.splice(index, 1)[0])
            }
          })
          this.skuAttributes = skuAttributes

          //skus 之遍历
          let sku_list = []
          platformGoods.skus.forEach((item) => {
            let obj = {}
            skuAttributes.forEach((elem) => {
              obj[elem.name] = item[elem.name]
            })
            item = Object.assign(item, obj)
            sku_list.push(item)
          })
          this.$nextTick(() => {
            this.sku_list = sku_list
            this.styles = styles
          })
        },
      })
    },
    judegInputType(type) {
      return type !== 'singleSelect'
    },
    specialPriceConfirm(scope) {
      if (this.activityPrice > scope.price) {
        this.activityPrice = ''
        this.$warning(this.$t('活动价不能高于商品原价'))
        return
      }
      if (!scope.specialFromDate || !scope.specialToDate) {
        this.$warning(this.$t('请选择活动时间'))
        return
      }
      http({
        instance: this,
        type: 'post',
        url: api.modifyLazadaGoodsPrice,
        hasLoading: true,
        data: {
          skuId: scope.skuId,
          price: scope.price,
          specialPrice: this.activityPrice,
          specialFromDate: scope.specialFromDate,
          specialToDate: scope.specialToDate,
        },
        success: () => {
          scope.specialPrice = this.activityPrice
          this.$success(this.$t(`操作成功`))
        },
      })
    },
    getActivityPriceTime(scope, date) {
      scope.specialFromDate = date.start_time
      scope.specialToDate = date.end_time
    },
    changeCategory(data) {
      if (data.value.length) {
        this.formData.primaryCategory = data.value[data.value.length - 1]
      } else {
        this.formData.primaryCategory = ''
      }
    },
    confirmCreate() {
      this.$refs.skuChild[0].submitData().then(({ styleTypeList, sku_list }) => {
        let lazadaSkuList = [],
          err_msg = ''
        sku_list.forEach((sku_item) => {
          let obj = {}
          let styleTypeNameList = sku_item.styleTypeName.split('\\')
          let skuStyleList = sku_item.styleType.split('\\').map((item, index) => ({
            styleType: item,
            styleValue: styleTypeNameList[index],
          }))
          sku_item.skuStyleList = skuStyleList
          if (sku_item.SkuId) {
            obj.skuId = sku_item.SkuId
          }
          if (sku_item.SellerSku) {
            obj.sellerSku = sku_item.SellerSku
          }
          if (sku_item.special_price && sku_item.price) {
            if (
              sku_item.special_price > sku_item.price * 1 ||
              sku_item.special_price < sku_item.price * 0.2
            ) {
              err_msg = `${sku_item.styleTypeName} ${this.$t(
                '活动价要小于原价，且要在原价的0.2-1.0倍之间'
              )}`
            }
          }
          if (sku_item.special_price && sku_item.special_from_date && sku_item.special_to_date) {
            /* empty */
          } else if (
            !sku_item.special_price &&
            !sku_item.special_from_date &&
            !sku_item.special_to_date
          ) {
            /* empty */
          } else {
            err_msg = `${
              sku_item.styleTypeName
            } Special Price、Start date of promotion、End date of promotion ${this.$t(
              '都填或都不填'
            )}`
          }
          obj.attributes = [sku_item]
          styleTypeList.forEach((item) => {
            if (item.imageAvailable && sku_item.styleType.indexOf(item.styleType) !== -1) {
              let imgList = item.value_list.find(
                (elem) => sku_item.styleTypeName.indexOf(elem.styleTypeName) > -1
              ).uploadImageUrlList
              obj.Images = imgList.map((elem) => elem.url)
            }
          })
          lazadaSkuList.push(obj)
        })
        if (err_msg) {
          this.$warning(err_msg)
          return
        }
        this.updatePlatformGoods(lazadaSkuList)
      })
    },
    updatePlatformGoods(lazadaSkuList) {
      let data = {
        itemId: this.formData.itemId,
        attributes: [{ ...this.formData }],
        lazadaSkuList,
        shopCode: this.shopCode,
      }
      http({
        instance: this,
        type: 'post',
        url: api.updatePlatformGoods,
        hasLoading: true,
        data,
        success: () => {
          this.$success(this.$t(`操作成功`))
          this.handleOk()
        },
      })
    },
    clear() {
      this.formData = {
        itemId: '',
        name: '',
        brand: '',
        categoryChain: '',
        primaryCategory: '',
        shortDescription: null,
        description: null,
        lazadaSkuList: [],
      }
    },
    auditLazadaGoods(success) {
      let itemIdList = [this.formData.itemId]
      http({
        instance: this,
        type: 'post',
        hasLoading: true,
        url: api.auditLazadaGoods,
        data: {
          itemIdList,
          success,
        },
        success: () => {
          this.$success(this.$t(`操作成功`))
          this.handleOk()
        },
      })
    },
    handleOk() {
      this.is_edit = false
      localStorage.setItem('refreshPlatformGoodsList', 1)
      this.$nextTick(() => {
        this.$router.push({ name: 'platformGoods' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hide {
  visibility: hidden;
  height: 0;
  opacity: 0;
  position: absolute;
  top: -100%;
  left: -100%;
}

.header-btn {
  padding-top: 10px;
  padding-left: 6px;
}

.title {
  font-size: 16px;
}

.image-list {
  display: flex;

  .image-item {
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;

    .image {
      width: 100px;
      height: 100px;
    }
  }
}

.text-info {
  cursor: pointer;
  text-align: left;
  // margin-bottom: 20px;
}

.content-wrapper {
  min-height: 0 !important;
}

.fixContent {
  // height: calc(100vh - 305px);
  overflow-y: auto;
}

.infoTitle {
  margin-top: 10px;
}
</style>
