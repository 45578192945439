<template>
  <div class="category-manage">
    <div class="content">
      <a-button type="primary" @click="addCategoryModal" v-if="priv('f_category_create_mapping')">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增类目') }}
      </a-button>
      <div class="form-content">
        <a-row>
          <a-col :span="5">
            <a-input
              allowClear
              v-model="formData.categoryName"
              :placeholder="$t(`请输入类目名称`)"
            ></a-input>
          </a-col>
          <a-col :span="5" style="margin-left: 15px">
            <a-button type="primary" @click="getDataList" v-if="priv('f_category_mapping_list')">
              <i class="icon iconfont icon-search" style="margin-right: 5px"></i>
              {{ $t(`查询`) }}
            </a-button>
            <a-button @click="reset">
              <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
              {{ $t(`重置`) }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="table-content">
        <a-table
          :rowKey="(record, index) => index"
          :columns="newColumns"
          :data-source="tableData"
          :pagination="false"
          :loading="tableLoading"
          bordered
        >
          <template slot="categoryName" slot-scope="text, record">
            <a v-if="record.isTitleEdit">
              <a-input style="width: 90px; margin-right: 5px" v-model="nowOmsName" placeholder="" />
              <i class="fa fa-check mr6" @click="saveOmsName(record)"></i>
              <i class="fa fa-close" @click="editOmsName('cancel')"></i>
            </a>
            <template v-else>
              {{ text }}
            </template>
          </template>
          <template slot="platformCategoryName" slot-scope="text, record">
            <categoryTree
              v-if="record.isEdit"
              ref="categoryTree"
              :show="true"
              :type="record.platform"
              :country="record.country"
              @changeCategory="changeCategoryTable($event, record)"
            />
            <template v-else>
              {{ text }}
            </template>
          </template>
          <template slot="platform" slot-scope="text, record">
            <span v-if="record.platform !== 'open'">{{ platformMapping[text] }}</span>
            <p
              v-else
              @click="openData(record, record.isOpen)"
              style="text-align: center; margin: 0"
            >
              <a>{{ record.isOpen === 'false' ? $t('展开') : $t('收起') }}</a>
            </p>
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.isEdit">
                <a @click="() => save(record)" style="margin-right: 10px">{{ $t('保存') }}</a>

                <a @click="() => cancel(record)">{{ $t('取消') }}</a>
              </span>
              <span v-if="!record.isEdit && priv('f_category_update_category_mapping')">
                <a @click="() => edit(record)">{{ $t('编辑') }}</a>
              </span>
            </div>
          </template>
        </a-table>
      </div>
      <div class="table-footer">
        <pagination
          :page-no="page_no"
          :length="page_size"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </div>
    <a-modal width="500px" v-model="visiable" :title="$t('新增类目')" @ok="createCategory">
      <a-form-model>
        <a-form-model-item class="black" :label="$t('类目名称')" required>
          <a-input v-model="modalData.categoryName"></a-input>
        </a-form-model-item>
        <div style="font-size: 16px">{{ $t(`映射关系`) }}</div>
        <div class="categoryModel">
          <template v-for="(otem, key) in platformObj">
            <div class="black" :key="key">
              <!-- <span style="color: red; font-size: 19px; margin-right: 5px">*</span> -->
              {{ key + $t(`类目`) }}
            </div>
            <a-form-model-item :label="item.country" v-for="item in otem" :key="item.code">
              <categoryTree
                ref="categoryTree"
                :show="true"
                :type="item.code"
                :country="item.country"
                @changeCategory="changeModalCategory($event, item.code + (item.country || ''))"
              ></categoryTree>
            </a-form-model-item>
          </template>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import categoryTree from '@component/categoryTree'
import pagination from '@component/pagination'
export default {
  name: 'categoryManage',
  components: { categoryTree, pagination },
  data() {
    return {
      formData: {
        categoryName: undefined,
      },

      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 0,
      visiable: false,
      nowOmsName: '', //记录当前OMs名字
      modalData: {
        categoryName: undefined,
      },
      nowCategory: '',
      oldTable: [], //存放老数据
      newColumns: [
        {
          title: 'OMS' + this.$t('类目'),
          dataIndex: 'categoryName',
          key: 'categoryName',
          width: 170,
          scopedSlots: { customRender: 'categoryName' },
          customCell: (record) => {
            if (+record.index === 0) {
              return {
                attrs: {
                  rowSpan: record.mergeRowLength,
                },
              }
            } else {
              return {
                style: {
                  display: 'none',
                },
                attrs: {
                  rowSpan: record.row,
                },
              }
            }
          },
        },
        {
          title: this.$t('平台'),
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
          scopedSlots: { customRender: 'platform' },
          customCell: (record) => {
            if (record.platform === 'open') {
              return {
                attrs: {
                  colSpan: 3,
                },
              }
            } else {
              if (record.rowSpan) {
                return {
                  attrs: {
                    rowSpan: record.rowSpan,
                  },
                }
              } else {
                return {
                  style: {
                    display: 'none',
                  },
                  attrs: {
                    rowSpan: record.row,
                  },
                }
              }
            }
          },
        },
        {
          title: this.$t('国家'),
          dataIndex: 'country',
          key: 'country',
          width: 150,
          scopedSlots: { customRender: 'country' },
        },
        {
          title: this.$t('类目'),
          dataIndex: 'platformCategoryName',
          key: 'platformCategoryName',
          scopedSlots: { customRender: 'platformCategoryName' },
          customCell: (record) => {
            return {
              style: {
                display: record.platform == 'open' ? 'none' : undefined,
              },
              attrs: {
                rowSpan: record.row,
              },
            }
          },
        },
        {
          title: this.$t('操作'),
          dataIndex: 'action',
          width: 100,
          key: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: (record) => {
            return {
              style: {
                display: record.platform == 'open' ? 'none' : undefined,
              },
              attrs: {
                rowSpan: record.row,
              },
            }
          },
        },
      ],
      tableLoading: false,
    }
  },
  computed: {
    ...mapGetters(['platformMapping']),
    platformList() {
      let arr = []
      arr = this.$store.state.nowPlatformList?.slice().sort((a, b) => a.code.localeCompare(b.code))

      return arr || []
    },
    platformObj() {
      let obj = {}
      this.platformList.forEach((item) => {
        let code = item.code
        if (obj[code]) {
          obj[code].push(item)
        } else {
          obj[code] = []
          obj[code].push(item)
        }
      })
      console.log(obj, 3333)
      return obj
    },
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    reset() {
      this.formData.categoryName = ''
      this.getDataList()
    },
    paginate(data) {
      this.page_no = data.pageNo
      this.page_size = data.pageSize
      this.getDataList()
    },
    // 获取列表页
    async getDataList() {
      this.tableLoading = true
      let res = await http({
        type: 'post',
        url: api.queryCategoryMappingPageList,
        data: {
          page_no: this.page_no,
          page_size: this.page_size,
          categoryName: this.formData.categoryName,
        },
      })
      let list = [...res.result.list]
      let newTable = this.addKey(list)
      this.oldTable = [...newTable]

      this.tableData = this.addKey(list, 3)
      this.total = res.result.total || 0
      this.tableLoading = false
    },
    addKey(list, length) {
      let newTable = []
      list.forEach((item, index) => {
        let arr = []
        let platformCategoryList = []

        this.platformList.forEach((otem, ondex) => {
          let index = arr.lastIndexOf(otem.code)
          let obj = {
            platform: otem.code,
            country: otem.country || '',
          }

          obj.categoryName = item.categoryName //添加OMS的类目名称
          obj.categoryId = item.categoryId //添加OMS的类目名称
          obj.mergeColLength = 3 //添加平台的数量，用来合并，这个为合并的行数
          obj.mergeRowLength =
            length > item.platformCategoryList.length
              ? item.platformCategoryList.length
              : length + 1 //用来合并oms类目名的计算方式，这个为合并的依据
          obj.index = ondex
          let nowCategoryList = item.platformCategoryList.find(
            (rtem) =>
              rtem.platform == obj.platform &&
              (rtem.country?.toUpperCase() || '') == (obj.country?.toUpperCase() || '')
          )

          if (nowCategoryList) {
            let name = nowCategoryList.platformCategoryNameList.slice().reverse().join('/')
            obj.platformCategoryName = name
            obj.platformCategoryKey = obj.platformCategoryName + Math.random()
          }
          if (index < 0) {
            arr.push(obj.platform)
            obj.rowSpan = this.platformList.filter((rtem) => rtem.code == obj.platform).length
            platformCategoryList.push(obj)
          } else {
            arr.splice(index, 0, otem.platform)
            obj.rowSpan = 0
            platformCategoryList.splice(index + 1, 0, obj)
          }
        })

        if (platformCategoryList.length) {
          let len
          if (length) {
            len = Math.min(length, platformCategoryList.length)
            while (platformCategoryList[len - 1].platform == platformCategoryList[len]?.platform) {
              len++
            }
            platformCategoryList.splice(len, platformCategoryList.length)
            platformCategoryList[0].mergeRowLength = len + 1
          }
          // if (len >= 3) {
          platformCategoryList.push({
            isOpen: 'false',
            platformCategoryNameList: [index],
            platform: 'open',
            categoryId: item.categoryId,
            categoryName: item.categoryName,
            mergeColLength: 3,
            mergeRowLength: len,
            platformCategoryName: '',
            index: platformCategoryList.length + 1,
            rowSpan: 1,
          })
          // }
        }

        newTable.push(...platformCategoryList)
      })

      return newTable
    },
    openData(value, type) {
      //   mergeRowLength 合并类目名这一列的数字
      let arr = []
      let endKey = 0
      let startKey = 0
      this.oldTable.forEach((item) => {
        if (item.categoryName == value.categoryName) {
          arr.push(item)
        }
      })
      this.tableData.forEach((item, index) => {
        if (item.categoryName == value.categoryName) {
          endKey = index
        }
      })
      arr.forEach((item, index) => {
        item.index = index

        if (item.platform != 'open') {
          item.mergeColLength = 3
          item.mergeRowLength = arr.length
        } else {
          item.mergeRowLength = value.mergeRowLength
        }
      })
      if (type === 'false') {
        startKey = endKey - value.mergeRowLength
        arr.forEach((item) => {
          if (item.isOpen === 'false') {
            item.isOpen = 'true'
          }
        })
        //这里首先删掉当前类目的所有数据，默认是四条数据加一个折叠 所以是5
        this.tableData.splice(startKey, value.mergeRowLength + 1)
        //从开始的位置,直接替换
        this.tableData.splice(startKey, 0, ...arr)
      } else {
        startKey = endKey + 1 - arr.length
        arr.forEach((item, index) => {
          item.index = index
          if (item.platform != 'open') {
            item.mergeColLength = 3
            item.mergeRowLength = value.mergeRowLength + 1
          }
          if (item.isOpen === 'true') {
            item.isOpen = 'false'
          }
        })
        // 要先删除掉展开的所有行 才能去替换。不然arr.length已经变化了
        this.tableData.splice(startKey, arr.length)
        // 这里的4代表 展开 折叠 这一行的key  length -5 代表 需要删除的行数
        arr.splice(value.mergeRowLength, arr.length - value.mergeRowLength - 1)

        //用新的Arr替换掉原来空出来的位置
        this.tableData.splice(startKey, 0, ...arr)
      }
    },
    changeCategoryTable(data, value) {
      this.nowCategory = {
        categoryId: value.categoryId,
        categoryName: value.categoryName,
        platform: value.platform,
        platformCategoryId: data.value.pop(),
        country: value.country,
      }
    },
    changeCategory(data, name, item) {
      if (data.value.length) {
        this.$set(item, name + 'DefaultValue', data.value)
        this.$set(item, name + 'DefaultName', data.text)
      } else {
        this.$set(item, name + 'DefaultValue', [])
      }
    },
    changeModalCategory(data, name) {
      if (data.value.length) {
        this.$set(this.modalData, name + 'Value', data.value)
        this.$set(this.modalData, name + 'Name', data.text)
      } else {
        this.$set(this.modalData, name + 'Value', [])
      }
    },
    edit(value) {
      this.nowCategory = ''
      let newData = [...this.tableData]
      newData.forEach((item) => {
        if (
          item.categoryId == value.categoryId &&
          item.platform == value.platform &&
          item.country == value.country
        ) {
          item.isEdit = true
        } else {
          item.isEdit = false
        }
      })
      this.tableData = newData
    },
    editOmsName(value) {
      this.nowOmsName = ''
      let newData = [...this.tableData]
      newData.forEach((item) => {
        if (value === 'cancel') {
          item.isTitleEdit = false
        } else {
          if (item.categoryName == value.categoryName) {
            item.isTitleEdit = true
          } else {
            item.isTitleEdit = false
          }
        }
      })
      this.tableData = newData
    },
    //取消保存
    cancel(value) {
      this.nowCategory = ''
      let newData = [...this.tableData]
      newData.forEach((item) => {
        if (
          item.platformCategoryId == value.platformCategoryId &&
          item.platform == value.platform
        ) {
          item.isEdit = false
        }
      })
      this.tableData = newData
    },
    //修改保存
    save(tableItem) {
      tableItem.loading = true
      if (this.nowCategory) {
        http({
          instance: this,
          type: 'post',
          url: api.updateWithPlatformCategory,
          hasLoading: true,
          data: this.nowCategory,
          success: () => {
            this.$success(this.$t(`操作成功`))
            this.getDataList()
          },
        })
      } else {
        this.$warning(this.$t(`请选择`))
      }
    },
    //修改保存
    saveOmsName(value) {
      if (this.nowOmsName) {
        http({
          instance: this,
          type: 'post',
          url: api.updateWithPlatformCategory,
          hasLoading: true,
          data: {
            categoryId: value.categoryId,
            categoryName: this.nowOmsName,
            platform: '',
            platformCategoryId: '',
          },
          success: () => {
            this.$success(this.$t(`操作成功`))
            this.getDataList()
          },
        })
      } else {
        this.$warning(this.$t(`请输入`))
        this.getDataList()
      }
    },
    addCategoryModal() {
      this.visiable = true
    },
    //新建
    createCategory() {
      if (!this.modalData.categoryName) {
        this.$warning(this.$t('请输入类目名称'))
        return
      }
      let platformCategoryList = []
      this.platformList.forEach((item) => {
        let platform = item.code
        let country = item.country || ''
        if (!this.modalData[platform + country + 'Value']?.length) return
        platformCategoryList.push({
          platform,
          country,
          platformCategoryId:
            this.modalData[platform + country + 'Value'][
              this.modalData[platform + country + 'Value'].length - 1
            ],
          platformCategoryName: this.modalData[platform + country + 'Name'],
        })
      })
      // if (platformCategoryList.length === 0) {
      //   this.$warning(this.$t('类目') + this.$t('至少选择一项'))
      //   return
      // }
      http({
        instance: this,
        type: 'post',
        url: api.createWithPlatformCategory,
        hasLoading: true,
        data: {
          categoryName: this.modalData.categoryName,
          platformCategoryList,
        },
        success: () => {
          this.$success(this.$t(`创建成功`))
          this.modalData = {
            categoryName: undefined,
          }
          this.visiable = false
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.category-manage {
  .form-content {
    padding-top: 10px;
    .ant-form-item {
      width: auto !important;
    }
  }
}
.categoryModel ::v-deep .ant-form-item {
  display: flex;
  align-items: center;
  .ant-form-item-label {
    width: 40px;
    text-align: right;
    color: #666 !important;
  }
  .ant-form-item-control-wrapper {
    margin-bottom: 0px;
  }
}
.black {
  color: black;
}
</style>
